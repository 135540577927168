import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as _ from 'lodash';
import { AssessRequestRaService } from 'src/app/assess-request-ra/assess-request-ra.service';
import { groupRulesDetailsModel } from 'src/app/common/models/GroupRulesDetailsModel';
import { pureRulesDetailsModel, ruleFlags } from 'src/app/common/models/PureRuleDetailsModel';
import { recipeModel } from 'src/app/common/models/recipeDetailsModel';
import { RuletypesAttributeMapping } from 'src/app/common/models/RuletypesAttributeMapping';
import { sirDetailObj } from 'src/app/common/models/SirModel';
import { DataService } from 'src/app/common/services/data.service';
import { WorklistService } from 'src/app/worklist/worklist.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-regulatory-function-rule-template',
    templateUrl: './regulatory-function-rule-template.component.html',
    styleUrls: ['./regulatory-function-rule-template.component.scss']
})
export class RegulatoryFunctionRuleTemplateComponent implements OnInit, AfterViewInit {
    @ViewChild('groupFunctionRuleForm')
    groupFunctionRuleForm: NgForm;
    activeTabGR = 'rule-detail';
    activeTab = 'substance-list';
    ankerCount: any;
    ruleTypeId; ruleDisplayName;
    selectedRuleDetails = []; allFns = []; ruleSpecificAttributes = []; selectedRuleTypeDetails = []; rpc = [];
    loading = false; readOnlyValue = true; funcAtrBtnClicked = false;
    groupDetails; groupDetailsInitial;
    subGroupDetails = []; rulesListForGroup = []; targetProducts = []; sourceProducts = [];
    equalityCondition = [{ value: 'IsEqual' }];
    funcWarningText = ''; ckeditorData = ''; ruleSource = ''; validityStartDate: any = ''; validityEndDate: any = '';
    saveAndAddNewDialog; ruleAdded; applicableGroupRuleTypesToDisplay; ruleTypeRadioSelected; rtRadioSelected;
    isAllCfSelected: boolean;
    name = 'Angular ';
    editor = ClassicEditor;
    saveAndAddNewBtnClicked: boolean; saveAndCloseClicked: boolean; isSaveButtonEnable: boolean;
    selectedFunctionValue = null;
    cancelClicked = false; editRuleFlow = false; viewRuleFlow = false;
    yearAdopted: any;
    ruleDescription = '';
    editRuleData; ruleIdOnEdit; ruleVersion;
    ruleSaveConformationPopup = false;
    ruleComment: string;
    loadingMsg; selectedRows;
    showRelatedRulesPopup = false; viewRelbtn = false;
    prepareRuleTableData = []; myWorklistTableColumns = []; selectedRowsRelated = [];
    showEmailConfirmationDialog = false; parallelRuleWorkflow = false;
    commentsToUser = ''; marketForModal = '';
    defaultValidityFromDate; defaultValidityEndDate; todayDate;
    warningTextOpen: boolean;
    tablewarningComment: any;
    orrTextOpen: boolean;
    tableORRComment: any;
    config = {
        toolbar: [
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            // '|',
            // 'insertTable',
            'Link'
        ],
        image: {
            toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
        },
        link: {
            addTargetToExternalLinks: true,
            decorators: [
              {
                mode: '',
                label: '',
                attributes: {
                  target: '_blank',
                }
              }
            ]
          }
    };
    constructor(
        private assessReqRAdataService: AssessRequestRaService, public snackBarGroup: MatSnackBar, public formBuilder: FormBuilder, public dataSvcObj: DataService,
        private datePipe: DatePipe, private worklistservice: WorklistService, private route: Router) { }
    ngAfterViewInit(): void {
        this.disableCheckbox();
    }
    disableCheckbox() {
        if (this.dataSvcObj.isfromSubstancesearch) {
            const picklistSource = document.getElementsByClassName('ui-picklist-source-wrapper').item(0) as HTMLElement;
            picklistSource.style.display = 'none';
            const picklistButton = document.getElementsByClassName('ui-picklist-buttons').item(0) as HTMLElement;
            picklistButton.style.display = 'none';
            const picklistTarget = document.getElementsByClassName('ui-picklist-target-wrapper').item(0) as HTMLElement;
            picklistTarget.style.cssFloat = 'left';
        }
    }
    /* istanbul ignore next */
    ngOnInit() {
        this.todayDate = new Date();
        this.defaultValidityFromDate = this.datePipe.transform(this.todayDate, 'longDate');
        this.defaultValidityEndDate = this.datePipe.transform(new Date('9999-12-31'), 'longDate');
        this.viewRuleFlow = false;
        if (this.activeTabGR === 'rule-detail') {
            document.getElementById('description').style.display = 'none';
        }
        // get selected rule type change
        this.dataSvcObj.getSelectedGroupRuleType.subscribe((value) => {
            if (value.length !== 0) {
                this.refreshTemplate();
                this.selectedRuleTypeDetails[0] = value;
                this.ruleTypeId = this.selectedRuleTypeDetails[0].ruleTypeId;
                this.ruleDisplayName = this.selectedRuleTypeDetails[0].ruleTypeDisplayName;
                this.activeTabGR = 'rule-detail';
                document.getElementById('rule-detail').style.display = 'block';
                document.getElementById('description').style.display = 'none';
                this.ruleDescription = this.selectedRuleTypeDetails[0].ruleTypeDescription ? this.selectedRuleTypeDetails[0].ruleTypeDescription : null;
                if (!this.editRuleFlow && !this.viewRuleFlow) {
                    this.ruleSource = '';
                    this.validityStartDate = '';
                    this.validityEndDate = '';
                    this.yearAdopted = '';
                }
                this.getGroupDetail();
                this.getFnList();
                this.groupDetails = this.groupDetailsInitial;
            }
        });
        if (this.ruleTypeId === 44) {
            this.getFnList();
            this.getRuleSpecificAttributeList();
        }
        if (ruleFlags.viewRule) {
            this.editRuleFlow = false;
            this.viewRuleFlow = true;
        } else if (ruleFlags.editRule) {
            this.editRuleFlow = true;
            this.viewRuleFlow = false;
        }
        this.dataSvcObj.getruleToEdit().subscribe((value) => {
            if (value !== null) {
                this.editRuleFlow = true;
                this.viewRelbtn = false;
                this.editRuleData = value;
                this.ruleSource = value.ruleSource;
                this.ruleSpecificAttributes.forEach((ruleattr) => {
                    if (ruleattr.attributeName === 'Warning' && value.negativeStatement) {
                        ruleattr.isRequired = true;
                        ruleattr.funcAttributeValue = value.negativeStatement;
                    }
                });
                if (ruleFlags.viewRule) {
                    this.defaultValidityFromDate = value.validityStartDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityStartDate, 'yyyy-MM-dd')), 'longDate')) : '';
                    this.defaultValidityEndDate = value.validityDate ? (this.datePipe.transform(new Date(this.datePipe.transform(value.validityDate, 'yyyy-MM-dd')), 'longDate')) : '';
                }
                this.yearAdopted = value.yearAdopted ? (new Date(value.yearAdopted)).toUTCString() : '';
                this.marketForModal = recipeModel.marketDetails.marketName;
                this.ruleIdOnEdit = value.ruleId;
                this.ruleVersion = value.ruleVersion;
                pureRulesDetailsModel.Market = value.markets[0];
                const rpcVals = [];
                value.rpcs.forEach((val) => {
                    const data = {
                        id: val.rpcId,
                        text: val.rpcName,
                        rpcFullText: val.rpcFullText
                    };
                    rpcVals.push(data);
                });
                pureRulesDetailsModel.selectedParentRpcs = rpcVals;
                pureRulesDetailsModel.selectedRpcs = rpcVals;
                recipeModel.preparationMethod = value.preparationMethod;
                recipeModel.intendedUse = value.intendedUse;
                this.getWorklistColumns();
            } else {
                this.editRuleFlow = false;
            }
        });
        // set group data on group selection change
        this.dataSvcObj.getGroupData.subscribe((groupData: any) => {
            this.refreshTemplate();
            this.groupDetailsInitial = groupData;
            this.groupDetails = Object.assign({}, this.groupDetailsInitial);
            if ((this.editRuleFlow || this.viewRuleFlow) && groupData && groupData.groupId) {
                this.sourceProducts = [];
                this.targetProducts = [];
                this.subGroupDetails = [];
                this.groupDetails = Object.assign({}, this.groupDetailsInitial);
                const data = groupData.substanceData;
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < this.editRuleData.substanceSubgroupsToSirList.length; j++) {
                        if (data[i].sirId === this.editRuleData.substanceSubgroupsToSirList[j].substanceId) {
                            data[i].ContributionFactor = data[i].cfDetails.filter(x => x.cf === this.editRuleData.substanceSubgroupsToSirList[j].contributionFactor)[0];

                            this.targetProducts.push(data[i]);
                        }
                    }
                }
                this.subGroupDetails = this.targetProducts;
                this.selectedFunctionValue = this.editRuleData.groupFunction;
                this.sourceProducts = _.xor(data, this.targetProducts);
            }
        });
    }
    /* istanbul ignore next */
    maxlenghtCheck(data, count, atributeName) {
        if (data !== null && data !== undefined) {
        const dataWithoutHtmlCount = data.replace(/<(.|\n)*?>/g, '').length + 1;
        const datawithhtmlCount = data.length;
        this.ankerCount = (data.match(/<a/g) || []).length;
        const diff = datawithhtmlCount - dataWithoutHtmlCount;
        if (count <= dataWithoutHtmlCount) {
            this.ruleSpecificAttributes.forEach((ruleattr) => {
                if (ruleattr.attributeName === atributeName) {
                    ruleattr.funcAttributeValue = ruleattr.funcAttributeValue.substring(0, count + diff);
                }
            });
            return false;
        }
    }
    }
    getGroupDetail() {
        this.dataSvcObj.getGroupData.subscribe((groupData) => {
            this.groupDetailsInitial = groupData;
            this.groupDetails = Object.assign({}, this.groupDetailsInitial);
        });
    }
    onTabChange(activeTab) {
        this.activeTabGR = activeTab;
        if (activeTab === 'rule-detail') {
            document.getElementById('rule-detail').style.display = 'block';
            document.getElementById('description').style.display = 'none';
            document.getElementById('ruleValidity').style.display = 'none';
        } else if (activeTab === 'description') {
            document.getElementById('rule-detail').style.display = 'none';
            document.getElementById('description').style.display = 'block';
            document.getElementById('ruleValidity').style.display = 'none';
        } else if (activeTab === 'ruleValidity') {
            document.getElementById('rule-detail').style.display = 'none';
            document.getElementById('description').style.display = 'none';
            document.getElementById('ruleValidity').style.display = 'block';
        }
        setTimeout(() => {
            this.disableCheckbox();
          }, 10);
    }
    onInnerTabChange(activeTab) {
        // set default CF details
        if (activeTab === 'contribution-factor' || activeTab === 'functiontab') {
            this.subGroupDetails.forEach((subgroupData) => {
                if (subgroupData.cfDetails && subgroupData.cfDetails.length > 0) {
                    if (!this.editRuleFlow && !this.viewRuleFlow) {
                        subgroupData.ContributionFactor = subgroupData.cfDetails[0].cfDisplayText;
                    } else {
                        if (!subgroupData.ContributionFactor) {
                            subgroupData.ContributionFactor = subgroupData.cfDetails[0].cfDisplayText;
                        }
                    }
                }
            });
            this.isAllCfSelected = true;
        }
        if (activeTab === 'functiontab') {
            this.subGroupDetails.forEach(subgroup => {
                subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                if (!subgroup.selectedCF) {
                    subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor.cfDisplayText)[0];
                }
            });
        }
        this.activeTab = activeTab;
    }
    // get function list
    getFnList() {
        const functionApiUrl = environment.APIFAsT + '/functions';
        this.assessReqRAdataService.getData(functionApiUrl).subscribe((response) => {
            this.allFns = response;
        });
    }
    onSaveAndClose() {
        this.saveAndCloseClicked = true;
        this.onSave();
    }
    onSaveandAddNew() {
        this.saveAndAddNewBtnClicked = true;
        this.onSave();
    }
    // save function rule
    onSave() {
        let ruleTypeId;
        let ruleType;
        const rpcData = [];
        const subgroupsubstancelist = [];
        const mktList = [{ marketId: pureRulesDetailsModel.Market.marketId, marketName: pureRulesDetailsModel.Market.marketName }];
        ruleTypeId = this.ruleTypeId;
        ruleType = this.ruleDisplayName;
        this.ruleSpecificAttributes.forEach((funcruleattr) => {
            if (funcruleattr.attributeName === 'Warning') {
                this.funcWarningText = funcruleattr.funcAttributeValue;
            }
        });

        if (groupRulesDetailsModel.CascadeRpc === 'Y') {
            pureRulesDetailsModel.selectedParentRpcs.forEach((value) => {
                const data = {
                    rpcFullText: value.rpcFullText,
                    rpcName: value.text,
                    rpcId: value.id
                };
                rpcData.push(data);
            });
        } else {
            pureRulesDetailsModel.selectedRpcs.forEach((value) => {
                const data = {
                    rpcFullText: value.rpcFullText,
                    rpcName: value.text,
                    rpcId: value.id
                };
                rpcData.push(data);
            });
        }
        // add subgroup details
        this.subGroupDetails.forEach(subgroup => {
            subgroupsubstancelist.push({
                SubstanceId: subgroup.sirId,
                ComponentName: subgroup.selectedCF.component,
                ContributionFactor: subgroup.selectedCF.cf,
                NumberOfExpressedAs: subgroup.selectedCF.numberOfExpressedAs,
                SubComponentMolWeight: subgroup.selectedCF.componentMolWeight,
                IsExempted: subgroup.isExempted
            });
        });
        // collect rule data
        const savedData = [{
            RuleId: this.ruleIdOnEdit ? this.ruleIdOnEdit : 0,
            RuleVersion: this.ruleVersion ? this.ruleVersion : 0,
            RPCs: rpcData,
            MarketID: Number(pureRulesDetailsModel.Market.marketId),
            Market: pureRulesDetailsModel.Market.marketName,
            Markets: mktList,
            RuleTypeID: ruleTypeId,
            RuleType: ruleType,
            CascadeMarket: 'N',
            CascadeRpc: 'Y',
            // passing NA uom when uom is not there
            UoMID: 2,
            UoM: 'N/A',
            TexualMinLimitText: '',
            TexualMaxLimitText: '',
            RuleDescription: '',
            RuleSource: this.ruleSource ? this.ruleSource : '',
            RuleSourceType: '',
            AdditionalStatement: '', // other requirements and restrictions
            PositiveStatement: '',
            MaxLimit: '',
            MinLimit: '',
            RuleClaim: '',
            RuleSpecificCondions: '',
            EqualityCondition: '',
            GroupFunction: this.selectedFunctionValue ? this.selectedFunctionValue : null,
            PreparationMethod: recipeModel.preparationMethod,
            IntendedUse: recipeModel.intendedUse,
            FunctionList: null, // used only for allowed function array of objects
            NegativeStatement: this.funcWarningText,
            IsMandatorySubstance: null, // Y for substance mandatory rule
            WarningText: null, // used for warning rule, adulterated rule , phased out rule, other requirements and restrictions, Product Warnings
            SubstanceGroup: this.groupDetails,
            SubstanceSubgroupsToSirList: subgroupsubstancelist,
            yearAdopted: this.yearAdopted ? this.datePipe.transform(this.yearAdopted, 'yyyy-MM-dd') : null,
            ValidityStartDate: this.validityStartDate ? this.datePipe.transform(this.validityStartDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityFromDate, 'yyyy-MM-dd'),
            ValidityDate: this.validityEndDate ? this.datePipe.transform(this.validityEndDate, 'yyyy-MM-dd') : this.datePipe.transform(this.defaultValidityEndDate, 'yyyy-MM-dd'),
            Comments: this.ruleComment ? this.ruleComment : null,
            RuleFlow: this.parallelRuleWorkflow === true ? 'Parallel' : 'Request'
        }];
        this.loading = true;
        this.loadingMsg = 'Saving Rule';
        if (!this.editRuleFlow) {
            const saveRuleToDbUrl = environment.APIFAsTRule + '/rulesList';
            this.assessReqRAdataService.postData(saveRuleToDbUrl, savedData)
                .subscribe(response => {
                    this.loading = false;
                    if (response[0].ruleId !== 0) {
                        this.ruleAdded = response;
                        this.getRuleDetailsFromDb();
                        this.snackBarGroup.open('Group Rule added successfully with Rule Id:' + response[0].ruleId, 'close', {
                            duration: 5000,
                            panelClass: ['success']
                        });
                        if (this.saveAndAddNewBtnClicked) {
                            setTimeout(() => {
                                this.dataSvcObj.getApplicableRuleTypesObsv.subscribe((res) => {
                                    if (res.length !== 0) {
                                        pureRulesDetailsModel.applicableRuleTypeList = res;
                                        this.applicableGroupRuleTypesToDisplay = res;
                                    }
                                });
                                this.applicableGroupRuleTypesToDisplay = pureRulesDetailsModel.applicableRuleTypeList;
                                this.saveAndAddNewDialog = true;
                                this.saveAndAddNewBtnClicked = false;
                            }, 3000);
                        } else
                            if (this.saveAndCloseClicked) {
                                this.dataSvcObj.setExpandablePanel(2);
                                this.saveAndCloseClicked = false;
                            }

                    } else {
                        this.snackBarGroup.open('Rule could not be created.', 'close', {
                            duration: 5000,
                            panelClass: ['error']
                        });
                    }
                }, (error) => {
                    this.loading = false;
                });
        } else {
            const url = environment.APIFAsTRule + '/rules';
            this.assessReqRAdataService.putData(url, savedData).subscribe((response) => {
                this.loading = false;
                if (response[0].ruleId !== 0) {
                    this.ruleAdded = response;
                    this.getRuleDetailsFromDb();
                    this.snackBarGroup.open('Rule updated successfully with Rule Id:' + response[0].ruleId + ' and Rule Version: ' + response[0].ruleVersion, 'close', {
                        duration: 5000,
                        panelClass: ['success']
                    });
                    this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
                        this.route.navigate(['/rule-management/view-rules']);
                    });
                } else {
                    this.snackBarGroup.open('Rule could not be updated.', 'close', {
                        duration: 5000,
                        panelClass: ['error']
                    });
                }
            }, (error) => {
                this.loading = false;
            });
        }
    }
    // get rule details from DB
    getRuleDetailsFromDb() {
        this.rpc = [];
        if (pureRulesDetailsModel.selectedRpcs !== undefined) {
            if (pureRulesDetailsModel.selectedRpcs[0].text !== 'No Regulatory Product Class is Mapped') {
                pureRulesDetailsModel.selectedRpcs.forEach((rpcValue) => {
                    this.rpc.push({
                        rpcId: rpcValue.id,
                        rpcName: rpcValue.text,
                        rpcFullText: rpcValue.rpcFullText,
                    });
                });
            }
        }
        const fetchRuleBody = {
            MarketList: [pureRulesDetailsModel.Market],
            RPCList: this.rpc,
            SIRIds: [sirDetailObj.sirId]
        };

        this.loading = true;
        const getruleapiurl = environment.APIFAsTRule + '/rules/rulesFilter';
        return new Promise<any>((resolve) => {
            this.assessReqRAdataService.postData(getruleapiurl, fetchRuleBody)
                .toPromise()
                .then(response => {
                    this.loading = false;
                    if (response !== null || response !== []) {
                        resolve(response);
                        const result = response.filter(x => x.ruleClassification === 'Substance Group Rules');
                        for (const value of result) {
                            value.ruleVerionToDisplay = 'V' + value.ruleVersion;
                            if (value.cfDetails !== null && value.cfDetails !== undefined) {
                                const cfValue = value.cfDetails;
                                value.cf = cfValue.cf;
                                value.cfSubstanceExpressedAs = cfValue.numberOfExpressedAs;
                            }
                            value.warningText = (value.warningText !== null) ? value.warningText : ((value.warningStatement !== null) ? value.warningStatement : '');
                            if (value.warningText !== null) {
                                value.originalWarningText = value.warningText;
                                value.warningTextStripped = this.handleSpecialCharacters(value.warningText);
                                value.warningText = this.changeComment(value.warningText);
                            } else {
                                value.warningTextStripped = '';
                            }
                            value.otherRestrictionsAndRequirements = (value.otherRestrictionsAndRequirements !== null) ? value.otherRestrictionsAndRequirements : '';
                            if (value.otherRestrictionsAndRequirements !== null) {
                                value.originalOtherRestrictionsAndRequirements = value.otherRestrictionsAndRequirements;
                                value.otherRestrictionsAndRequirements = this.changeComment(value.otherRestrictionsAndRequirements);
                                value.otherRestrictionsAndRequirementsStripped = this.handleSpecialCharacters(value.otherRestrictionsAndRequirements);
                            } else {
                                value.otherRestrictionsAndRequirementsStripped = '';
                            }
                        }
                        this.dataSvcObj.setRulesListForGroup(result);
                        this.rulesListForGroup.push(response.filter((value) => value.groupID === this.groupDetails.groupId));
                    }
                }).catch(error => {
                    this.loading = false;
                });
        });
    }
    handleSpecialCharacters(text) {
        return text.replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;.*?/gi, '').replace(/&amp;/g, '&');
    }
    onNextButtonClick(tabname) {
        if (this.activeTab === 'substance-list' && (this.editRuleFlow || this.viewRuleFlow)) {
            this.subGroupDetails = this.targetProducts;
        }
        if (tabname === 'contribution-factor') {
            this.subGroupDetails.forEach((subgroupData) => {
                if (subgroupData.cfDetails && subgroupData.cfDetails.length > 0) {
                    if (!this.editRuleFlow && !this.viewRuleFlow) {
                        subgroupData.ContributionFactor = subgroupData.cfDetails[0].cfDisplayText;
                    } else {
                        if (!subgroupData.ContributionFactor) {
                            subgroupData.ContributionFactor = subgroupData.cfDetails[0].cfDisplayText;
                        }
                    }
                }
            });
            this.isAllCfSelected = true;
        }
        if (this.activeTab === 'contribution-factor') {
            this.subGroupDetails.forEach(subgroup => {
                subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor)[0];
                if (!subgroup.selectedCF) {
                    subgroup.selectedCF = subgroup.cfDetails.filter(x => x.cfDisplayText === subgroup.ContributionFactor.cfDisplayText)[0];
                }
            });
            if ((this.editRuleFlow || this.viewRuleFlow) && this.editRuleData.groupFunction) {
                this.selectedFunctionValue = this.editRuleData.groupFunction;
            }
        }
        this.activeTab = tabname;
    }

    onCancel() {
        if (!this.editRuleFlow) {
            this.subGroupDetails.forEach((element) => {
                element.ContributionFactor = '';
                this.groupDetails.substanceData.push(element);
            });
            this.subGroupDetails = [];
            this.activeTab = 'substance-list';
            this.dataSvcObj.setExpandablePanel(2);
            this.isSaveButtonEnable = false;
        } else {
            this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
                this.route.navigate(['/rule-management/view-rules']);
            });
        }
    }
    // change ruletype from add rule popup
    ruleTypeRadioButtonChanged(event) {
        this.ruleTypeRadioSelected = true;
        this.rtRadioSelected = event.target.value;
    }

    // continue after rule type change from add rule popup
    /* istanbul ignore next */
    onContinue() {
        if (this.rtRadioSelected !== undefined) {
            const obj = _.filter(pureRulesDetailsModel.applicableRuleTypeList, (element) => element.ruleTypeDisplayName === this.rtRadioSelected);
            // tslint:disable-next-line: no-shadowed-variable
            const obj1 = obj.reduce((obj, newObj, i) => {
                newObj[i] = obj;
                return newObj;
            }, {});
            this.saveAndAddNewDialog = false;
            this.dataSvcObj.setSelectedGroupRuleType(obj1);
            this.dataSvcObj.setExpandablePanel(3);
            this.isSaveButtonEnable = false;
        }
    }
    // enable save button after function change
    functionChange() {
        this.isSaveButtonEnable = true;
    }
    parseDate(dateString: string): Date {
        if (dateString) {
            return new Date(dateString);
        }
        return null;
    }
    // to refresh template
    refreshTemplate() {
        this.subGroupDetails.forEach((element) => {
            element.ContributionFactor = '';
            const index = this.groupDetails.substanceData.findIndex(object => object.sirId === element.sirId);
            if (index === -1) {
                this.groupDetails.substanceData.push(element);
            }
        });
        this.removefunctionattr();
        this.selectedFunctionValue = null;
        this.subGroupDetails = [];
        this.activeTab = 'substance-list';
        this.isSaveButtonEnable = false;
    }
    removefunctionattr() {
        this.ruleSpecificAttributes.forEach((element) => {
            element.funcAttributeValue = null;
            element.isRequired = false;
        });
    }
    /* istanbul ignore next */
    checkRulesAvail() {
        this.viewRelbtn = true;
        this.prepareRuleTableData = [];
        this.selectedRows = null;
        this.loadingMsg = 'Fetching data from FAsT Rule-Engine';
        this.loading = true;
        const apiurl = environment.APIFAsTRule + '/rules/queryrelatedrequests';
        const body = [];
        this.showRelatedRulesPopup = true;
        body.push({
            RuleId: this.ruleIdOnEdit,
            RuleVersion: this.ruleVersion
        });
        this.assessReqRAdataService.postData(apiurl, body).subscribe((response) => {
            if (response) {
                this.loading = false;
                this.prepareRuleTableData = response.map(funReq => {
                    return {
                        requestDisplayID: funReq.requestDisplayID,
                        cucCode: funReq.cucCode,
                        cucDescription: funReq.cucDescription,
                        recipeType: funReq.recipeType,
                        recipeStatus: funReq.recipeStatus,
                        marketId: funReq.market.marketId,
                        marketName: funReq.market.marketName,
                        division: (funReq.tfc.tfcLevel === 5) ? funReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcName : funReq.tfc.parentTFC.parentTFC.parentTFC.tfcName,
                        rDFormulationCategory: (funReq.tfc.tfcLevel === 5) ? funReq.tfc.parentTFC.parentTFC.parentTFC.tfcName : funReq.tfc.parentTFC.parentTFC.tfcName,
                        formulationType: (funReq.tfc.tfcLevel === 5) ? funReq.tfc.parentTFC.parentTFC.tfcName : funReq.tfc.parentTFC.tfcName,
                        formulationClass: (funReq.tfc.tfcLevel === 5) ? funReq.tfc.parentTFC.tfcName : funReq.tfc.tfcName,
                        formulationSubClass: (funReq.tfc.tfcLevel === 5) ? funReq.tfc.tfcName : '',
                        preparationMethod: funReq.preparationMethod,
                        intendedUse: funReq.intendedUse,
                        multicomponent: funReq.isMultiComponent,
                        requestStatus: funReq.requestStatus,
                        ra: funReq.ra,
                        allRa: funReq.allRA,
                        submittedDate: funReq.submittedDate,
                        submittedByUserId: funReq.submittedByUserId,
                        submittedByUserName: funReq.submittedByUserName,
                        comments: funReq.comments,
                        assignedDate: funReq.assignedDate,
                        inProgressRADate: funReq.inProgressRADate,
                        inProgressRDDate: funReq.inProgressRNDDate,
                        inProgressSIRDate: funReq.inProgressSIRDate,
                        rejectedDate: funReq.rejectedDate,
                        deletionDate: funReq.deletionDate,
                        completedDate: funReq.completedDate,
                        rdComment: funReq.comments,
                        raComment: funReq.raComment,
                        topTfcId: funReq.tfcid,
                        allRAIDs: funReq.allRAIDs,
                        raUserID: funReq.raUserIDred,
                        divisionid: (funReq.tfc.tfcLevel === 5) ? funReq.tfc.parentTFC.parentTFC.parentTFC.parentTFC.tfcId : funReq.tfc.parentTFC.parentTFC.parentTFC.tfcId
                    };
                });
            }
            const ele = document.getElementById('echeck') as HTMLInputElement;
            ele.checked = false;
            this.selectedRowsRelated = [];
        }, (error) => {
            this.loading = false;
        });
    }
    getWorklistColumns() {
        const apiurl = environment.APIFAsT + '/getworklistcolumns/relatedrequest';
        this.worklistservice.getWorklistApiData(apiurl).subscribe((response) => {
            this.myWorklistTableColumns = response;
        });
    }
    sendEmail() {
        this.showEmailConfirmationDialog = true;
        let reqId = '';
        let userDetails = '';
        const uDetail = [];
        const reDet = [];
        this.selectedRowsRelated.forEach(element => {
            reDet.push(element.requestDisplayID);
        });
        const distinctReqs = reDet.filter((n, i) => reDet.indexOf(n) === i);
        reqId = distinctReqs.join(' ');
        this.selectedRowsRelated.forEach(element => {
            uDetail.push(element.ra);
        });
        const distinctNames = uDetail.filter((n, i) => uDetail.indexOf(n) === i);
        userDetails = distinctNames.join(' ');
        this.commentsToUser = 'Regulatory rule/s for the request no. ' + reqId + ' will be updated/deleted by RA user ' + userDetails + ' .To get the most updated FAsT report, please submit a new request for the formulation. Ignore if the formulation is no longer valid.';
    }
    sendEmailMessage() {
        const payLoad = {
            NotificationGroup: 'RDCOMPLETED',
            Subject: 'FAsT | Rules updated for these requests :',
            EmailBody: null,
            ToEmailList: '',
            FromEmail: null,
            CCEmailList: null,
            EmailType: 'html',
            UPD: this.ruleIdOnEdit.toString(),
            PureCodes: null,
            UserRequests: [],
            CUCDescription: null,
            Comments: this.commentsToUser
        };

        if (this.selectedRowsRelated.length > 0) {
            this.selectedRowsRelated.forEach(row => {
                const reqIds = this.selectedRowsRelated.filter(item => item.submittedByUserId === row.submittedByUserId).map(i => i.requestDisplayID);
                payLoad.UserRequests.push({
                    userId: +row.submittedByUserId,
                    requestIds: reqIds
                });
            });
        }

        if (payLoad.UserRequests.length > 0) {
            this.assessReqRAdataService.sendRulesEmailNotification(payLoad);
        }
        this.showEmailConfirmationDialog = false;
        this.showRelatedRulesPopup = false;
    }
    onGroupSelect(checkValue) {
        if (checkValue) {
            this.selectedRowsRelated = this.prepareRuleTableData;
        } else {
            this.selectedRowsRelated = [];
        }
    }
    onClickedOutsideFunc() {
        this.funcAtrBtnClicked = false;
    }
    // add attribute button click
    onFunctionAddAtrBtnClicked() {
        this.funcAtrBtnClicked = !this.funcAtrBtnClicked;
    }
    // remove combination ruletype attribute
    removeFuncRTAttribute(value, index: number) {
        value.funcAttributeValue = null;
        this.ruleSpecificAttributes.filter(x => x.attributeName === value.attributeName)[0].isRequired = false;
    }
    // clear combination rule attribute data on row delete
    removeFuncRTAttributeHide(value, index: number) {
        value.funcAttributeValue = null;
    }
    // rule specific attribute list
    getRuleSpecificAttributeList() {
        const ruleId = this.ruleTypeId;
        const ruledetails = RuletypesAttributeMapping.filter(obj => obj.ruleBucket === 'Group Based' && obj.ruleTypeId === ruleId)[0];
        this.groupDetails = Object.assign({}, ruledetails);
        const rsListAttr = this.groupDetails.attributeList;
        this.ruleSpecificAttributes = rsListAttr.map(x => Object.assign({}, x));
        this.ruleSpecificAttributes.filter(x => x.attributeTemplate === 'SetText').forEach(attr => {
            if (attr.attributeName === 'Warning') {
                attr.funcAttributeValue = this.funcWarningText;
            }
        });
    }
          /* istanbul ignore next */
  openComment(data) {
    this.warningTextOpen = true;
    const mydata = data.originalWarningText;
    this.tablewarningComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  /* istanbul ignore next */
  openCommentORR(data) {
    this.orrTextOpen = true;
    const mydata = data.originalOtherRestrictionsAndRequirements;
    this.tableORRComment = mydata.replace(new RegExp('<a', 'g'), '<a target="_blank"');
  }
  closePopup() {
    this.warningTextOpen = false;
  }
  closePopupORR() {
    this.orrTextOpen = false;
  }
  /* istanbul ignore next */
  changeComment(input) {
    const strValue = input;
    const ar = strValue.match(/<a.*?<\/a>/g);
    let commentwarning = '';
    if ((ar !== null) && (ar.length === 0 || ar.length > 0)) {
      commentwarning = 'Click Here';
    } else {
      commentwarning = input;
    }
    return commentwarning;
  }
}
